import storageHelper from '@helpers/storage.helper';
// import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const Footer = () => {
  const [termsUrl, setTermsUrl] = useState<string>('/terms-of-services');
  const [privacyUrl, setPrivacyUrl] = useState<string>('/privacy-policy');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userData = storageHelper.get(
        `${process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_PROFILE}`
      );
      const userToken = storageHelper.get(
        `${process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_CHAKRA}`
      );

      if (userData && userToken) {
        setTermsUrl('/account/help/terms-of-service');
        setPrivacyUrl('/account/help/privacy-policy');
      }
    }
  }, []);

  return (
    <footer className="relative px-[0] py-[20px] md:px-[60px] md:py-[60px] border-t border-solid border-gray-400">
      <div className="relative flex flex-col md:flex-row justify-between">
        <div className="flex flex-col md:flex-row justify-between w-full md:w-3/4 px-[18px] py-[8px]">
          <div className="flex flex-col justify-between flex-[2]">
            <div className="w-[150px] h-auto">
              <img
                className="w-full h-full object-contain object-center"
                src="/images/brands/chakra.webp"
                alt="Chakra"
              />
            </div>

            <div className="mt-[10px]">
              <p className="text-[12px] text-gray-600">
                &copy; {format(new Date(), 'yyyy')} chakrarewards.com. All rights reserved
              </p>
              <div className="flex items-center space-x-2">
                <Link href={termsUrl}>
                  <a className="text-[12px] font-medium">Term of Service</a>
                </Link>
                <span className="text-gray-400">|</span>
                <Link href={privacyUrl}>
                  <a className="text-[12px] font-medium">Privacy Policy</a>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex-[1] flex flex-col justify-between">
            <div className="flex items-center mb-[10px]">
              <a
                href="https://facebook.com/KALBEFamily/"
                className="flex-grow cursor-pointer text-gray-500"
                target="_blank"
                rel="noreferrer">
                {/* <Facebook sx={{ fontSize: 30 }} /> */}
                <Image src="/images/icons/facebook.svg" alt="facebook" width={30} height={30} />
              </a>
              <a
                href="https://twitter.com/Kalbe_Family"
                className="flex-grow cursor-pointer text-gray-500"
                target="_blank"
                rel="noreferrer">
                {/* <Twitter sx={{ fontSize: 30 }} /> */}
                <Image src="/images/icons/twitter.svg" alt="twitter" width={30} height={30} />
              </a>
              <a
                href="https://instagram.com/kalbe_family"
                className="flex-grow cursor-pointer text-gray-500"
                target="_blank"
                rel="noreferrer">
                {/* <Instagram sx={{ fontSize: 30 }} /> */}
                <Image src="/images/icons/instagram.svg" alt="instagram" width={30} height={30} />
              </a>
            </div>

            {/* <ul>
              <li>
                <a className="text-[12px] font-medium cursor-pointer">How it works</a>
              </li>
              <li>
                <a className="text-[12px] font-medium cursor-pointer">Blog</a>
              </li>
            </ul> */}
          </div>
        </div>

        <div className="relative w-full">
          <div className="relative w-full h-[340px] md:h-[180px] rounded-none md:rounded-lg overflow-hidden">
            <img
              className="w-full h-full object-cover object-center"
              src="/images/backgrounds/bg_getapp.webp"
              data-img-src="/images/backgrounds/bg_getapp.webp"
              loading="lazy"
              alt="Background"
            />
          </div>

          <div className="absolute top-[0] left-[0] w-full h-full flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center p-[30px]">
            <div>
              <h3 className="text-white h3 font-medium mb-2">Get Contact</h3>
              <div>
                <p className="text-white small">
                  We Want to Know You More. <br /> relax, your information are safe and by telling
                  us more
                </p>
              </div>
            </div>

            <div className="mt-4 ml-0 md:mt-0 md:ml-2">
              <Link href="/contact-us" passHref>
                <button
                  className="button button--base button--brand button--outlined"
                  data-unq="landingpage-button-contact">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
